<script lang="ts">
    import type { PageData } from './$types';

    import FaYoutube from 'svelte-icons/fa/FaYoutube.svelte';
    import ProjectLink from '$lib/components/project_link.svelte';

    import { locale, _ } from 'svelte-i18n';
    import { defaultLocale } from '$lib/translations';
    import LangMenu from '$lib/components/lang_menu.svelte';
    import ThemeMenu from '$lib/components/theme_menu.svelte';

    export let data: PageData;
</script>

<svelte:head>
    <title>Ele's gateway</title>
</svelte:head>

<div class="flex flex-col h-screen">
    <div class="flex flex-row-reverse">
        <ul class="menu lg:menu-horizontal px-1">
            <li>
                <LangMenu />
            </li>
            <li>
                <ThemeMenu />
            </li>
        </ul>
    </div>

    <div class="xl:w-1/2 w-full self-center my-3">
        <h1 class="text-5xl">{data.author.name}</h1>
        <p>{data.author.localizations[$locale || defaultLocale].intro}</p>
    </div>

    <div class="flex xl:w-1/2 w-full self-center justify-between">
        <div class="flex space-x-3">

            <a class="text-red-500 icon" href={data.author.social.youTubeUrl}>
                <FaYoutube />
            </a>
            <!-- <a class="text-red-500 icon" href={data.author.social.youTubeUrl}> -->
            <!--     <FaYoutube /> -->
            <!-- </a> -->
            <!-- <a class="text-red-500 icon" href={data.author.social.youTubeUrl}> -->
            <!--     <FaYoutube /> -->
            <!-- </a> -->
        </div>

        <div class="flex-shrink flex flex-col space-y-5">
            <ProjectLink
                link="/portfolio"
                title={$_('portfolio')}
                background="https://media.graphassets.com/y9Hrmha2SkaxkXv3OEpq"
            />

            <!-- <ProjectLink link="https://ralealeilani.eleazardzdo.dev" title="Blog" /> -->
            <!---->
            <!-- <ProjectLink link="https://ralealeilani.eleazardzdo.dev" title="Ralea Leilani" /> -->
        </div>
    </div>
</div>

<style>
    .icon {
        display: block;
        width: 32px;
        height: 32px;
    }
</style>

<script lang="ts">
    export let link: string;
    export let title: string;
    export let background: string = "";
</script>

<a href={link} class=" btn btn-lg btn-wide glass" style="--bg-link-project: url('{background}')">
    <!-- <h1 class="text-4xl text-right highlight-text"> -->
        {title}
    <!-- </h1> -->
</a>

<style>
    .highlight-text {
        text-shadow: 0 0 10px white;
    }

    .project_link {
        display: block;
        clip-path: polygon(10% 100%, 100% 100%, 100% 0%, 0% 0%);
        background-image: var(--bg-link-project);
        background-size: cover;
        background-color: #428bca;
        margin: 1em;
        padding: 1.5em;
    }
</style>
